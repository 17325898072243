<template>
  <div class="columns is-mobile">
    <div
      class="column is-four-fifths"
      :class="isFromMe ? 'is-offset-one-fifth' : ''"
    >
      <div
        class="columns is-mobile"
        :class="isFromMe ? 'reverse-me' : ''"
      >
        <div
          class="column is-narrow"
          style="margin-top: auto;"
        >
          <figure
            class="image is-square"
            :class="isFromMe ? 'is-16x16' : 'is-32x32'"
          >
            <img
              :src="userPicUrl"
              style="border-radius: 50%"
            >
          </figure>
        </div>

        <div class="column is-mobile-narrow is-inline-flex normal-section">
          <div
            v-if="!isFromMe && reactionsOpened"
            id="reactionsband"
            class="container has-background-light"
          >
            <span
              v-for="availableReaction in availableReactions"
              :key="availableReaction"
              class="tag is-rounded"
              :class="(availableReaction === item.reaction) ? 'has-background-link' : ''"
              @click="setReaction(availableReaction)"
            >
              {{ availableReaction }}
            </span>
          </div>

          <p
            v-once
            class="is-size-7 has-text-gray-lighter"
            :class="isFromMe ? 'pull-me' : ''"
            style="font-weight:300"
          >
            {{ item.timestamp | formatDate }}
          </p>

          <article
            v-if="item.message"
            class="message is-flex"
            :class="isFromMe ? 'message-me' : 'message-you'"
          >
            <p
              v-if="isOnlyEmojis"
              class="message-body-emoji"
            >
              {{ item.message }}
            </p>
            <p
              v-else
              class="message-body"
              :data-badge="item.reaction"
              :class="[isFromMe ? 'message-body-me' : 'message-body-you', item.reaction ? 'badge has-badge-medium has-badge-white has-badge-rounded' : '']"
            >
              <span v-html="htmlMessage" />
            </p>
          </article>

          <iframe
            v-for="iframeSource in iframeSources"
            :key="iframeSource"
            :style="isFromMe ? 'align-self: flex-end;' : ''"
            :src="iframeSource"
            width="300"
            height="300"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />

          <figure
            v-if="item.mediaUri && isPicture"
            class="image"
          >
            <img :src="mediaUrl">
          </figure>
          <figure
            v-if="stickerUrl"
            class="image"
          >
            <img :src="stickerUrl">
          </figure>
          <video
            v-if="item.mediaUri && !isPicture"
            preload="metadata"
            controls
            :src="mediaUrl"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emojiRegex from 'emoji-regex'
import moment from 'moment'
import anchorme from 'anchorme'

export default {
  name: 'Message',

  filters: {
    formatDate(value) {
      return moment.unix(value).locale('fr').calendar()
    }
  },
  props: ['item', 'reactionsOpened'],
  data() {
    return {
      userPicUrl: undefined,
      mediaUrl: undefined,
      stickerUrl: undefined,
      availableReactions: ['😍', '😆', '😂', '🤪', '😮', '😥', '😠', '👍', '👎']
    }
  },

  computed: {
    isPicture() {
      if (this.item.photoUri || this.item.stickerUri) {
        return true
      } else if (this.item.videoUri) {
        return false
      } else if (this.item.mediaUri) {
        return (this.item.mediaUri[0] !== 'v')
      } else {
        return false
      }
    },

    htmlMessage() {
      return this._getHtmlMessage()
    },

    isFromMe() {
      return this._isFromMe()
    },

    isOnlyEmojis() {
      return this._isOnlyEmojis()
    },

    iframeSources() {
      return this._getIframeSources()
    }
  },

  mounted() {
    this.$store.dispatch('getStorageUrl', this.item.userPicUri).then(url => {
      this.userPicUrl = url
    })
    this.$store.dispatch('getStorageUrl', this.item.mediaUri).then(url => {
      this.mediaUrl = url
    })
    this.$store.dispatch('getStorageUrl', this.item.stickerUri).then(url => {
      this.stickerUrl = url
    })
  },

  methods: {
    setReaction(availableReaction) {
      if (this.item.reaction === availableReaction) {
        availableReaction = null
      }
      this.$emit('setReaction', this.item.key, availableReaction)
    },

    _isFromMe() {
      return (this.item.userId === this.$store.state.myId)
    },

    _isOnlyEmojis() {
      const regex = emojiRegex()
      if (this.item.message) {
        const strippedMessage = this.item.message.replace(regex, '')
        return strippedMessage.length === 0
      }
      return undefined
    },

    _getIframeSources() {
      if (!this.item.message) return undefined
      const anchors = anchorme.list(this.item.message)
      return anchors
        .filter(anchor => anchor.reason === 'url')
        .map(anchor => new URL(anchor.string))
        .filter(anchorUrl => {
          if (anchorUrl.hostname === 'www.deezer.com') return true
          if (anchorUrl.hostname === 'youtu.be') return true
          if (anchorUrl.hostname === 'www.facebook.com') return true
        })
        .map(anchorUrl => {
          if (anchorUrl.hostname === 'www.deezer.com') {
            const pathElements = anchorUrl.pathname.split('/')
            const deezerType = pathElements[1]
            if (deezerType === 'track') {
              const deezerId = pathElements[2]
              return `https://www.deezer.com/plugins/player?format=square&autoplay=false&playlist=false&width=300&height=300&color=007FEB&layout=dark&size=medium&type=tracks&id=${deezerId}&app_id=1`
            }
            return undefined
          }
          if (anchorUrl.hostname === 'youtu.be') {
            return `https://www.youtube-nocookie.com/embed${anchorUrl.pathname}`
          }
          if (anchorUrl.hostname === 'www.facebook.com') {
            const pathElements = anchorUrl.pathname.split('/')
            const facebookType = pathElements[2]
            if (facebookType === 'posts') {
              return `https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(anchorUrl)}`
            }
            if (facebookType === 'videos') {
              return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(anchorUrl)}`
            }
            return undefined
          }
        })
    },

    _getHtmlMessage() {
      return this.item.message && anchorme(this.item.message, {
        attributes: [
          {
            name: 'target',
            value: '_blank'
          }
        ]
      })
    }
  }
}
</script>

<style scoped>
article.message {
  background-color: transparent;
}

.reverse-me {
  flex-direction: row-reverse;
}

.pull-me {
  text-align: right;
}

.message-body {
  border-radius: 25px;
  border: 0px;
  padding: 0.75em 1em;
  font-size:90%;
  word-break: break-word;
  white-space: pre-wrap;
}

.message-me {
  flex-direction: row-reverse;
}

.message-body-you {
  background: #e0e0e0;
  color: #212121;
}

.message-body-me {
  background: #1976D2;
  color: #fafafa;
}

.message-body-emoji {
  background: none;
  font-size: xx-large;
  padding: 0;
  line-height: 1;
}

.normal-section {
  flex: inherit;
  flex-direction: column;
  padding-left:0px;
  padding-right:0px;
}

.badge {
  white-space: inherit;
}

#reactionsband {
  position: absolute;
  z-index: 100;
  border-radius: 5px;
}

</style>
