import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import device from 'current-device'
import { db, storage, auth } from '../config'
import { setPersistence, inMemoryPersistence, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { ref, onValue, child, push, onDisconnect, get, set, serverTimestamp } from 'firebase/database'
import { ref as storageRef, getDownloadURL } from 'firebase/storage'

// Make sure to call Vue.use(Vuex) first if using a module system
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    myId: undefined,
    myName: undefined,
    myPicUri: undefined,
    myConnectionRef: undefined,
    myLastOnlineRef: undefined,
    yourId: undefined,
    yourLastOnline: undefined,
    yourName: undefined,
    yourPicUri: undefined,
    yourTyping: undefined,
    urlCache: {}
  },

  mutations: {
    setMyId(state, uid) {
      state.myId = uid
    },

    setMyName(state, name) {
      state.myName = name
    },

    setMyPicUri(state, picUri) {
      state.myPicUri = picUri
    },

    setMyConnectionRef(state, connectionRef) {
      state.myConnectionRef = connectionRef
    },

    setMyLastOnlineRef(state, lastOnlineRef) {
      state.myLastOnlineRef = lastOnlineRef
    },

    setYourId(state, uid) {
      state.yourId = uid
    },

    setYourLastOnline(state, lastOnline) {
      state.yourLastOnline = lastOnline
    },

    setYourName(state, name) {
      state.yourName = name
    },

    setYourPicUri(state, picUri) {
      state.yourPicUri = picUri
    },

    setYourTyping(state, isTyping) {
      state.yourTyping = isTyping
    },

    resetStore(state) {
      state.myId = undefined
      state.myName = undefined
      state.myPicUri = undefined
      state.myConnectionRef = undefined
      state.myLastOnlineRef = undefined
      state.yourId = undefined
      state.yourLastOnline = undefined
      state.yourPicUri = undefined
      state.yourName = undefined
      state.yourTyping = undefined
    },

    addUrlToCache(state, { uri, url }) {
      Vue.set(state.urlCache, uri, url)
    }
  },

  actions: {
    logMeIn: async(context, credentials) => {
      await setPersistence(auth, inMemoryPersistence)
      const userCredential = await signInWithEmailAndPassword(auth, credentials.email, credentials.password)
      const myId = userCredential.user.uid

      const myUserRef = ref(db, `users/${myId}`)
      const snapshot = await get(myUserRef)
      if (!snapshot.exists()) throw new Error('no user found')
      const myName = snapshot.val()?.name
      const myPicUri = snapshot.val()?.picUri

      // Register my id and return resolved Promise
      context.commit('setMyId', myId)
      context.commit('setMyName', myName)
      context.commit('setMyPicUri', myPicUri)
      await context.dispatch('getStorageUrl', myPicUri)

      // Register presence listeners for me
      const connectedRef = ref(db, '.info/connected')
      onValue(connectedRef, async snapshot => {
        if (snapshot.val() === true) {
          // Add this connection to my connections list
          const myConnectionsRef = ref(db, `connections/${myId}`)
          const myConnectionRef = push(myConnectionsRef)
          // When I disconnect, write timestamp of this connection
          const myConnectionEndedRef = child(myConnectionRef, 'ended')
          await onDisconnect(myConnectionEndedRef).set(serverTimestamp())
          // On connection, write timestamp of this connection
          const myConnectionStartedRef = child(myConnectionRef, 'started')
          await set(myConnectionStartedRef, serverTimestamp())
          context.commit('setMyConnectionRef', myConnectionRef)
          // Now obtain device details and IP-based location
          const details = {
            device: device.type,
            os: device.os
          }
          try {
            const response = await axios.get('https://api.ipdata.co?api-key=cdef2b0188f3898b3bce280985eb92ec715c38eb3638a3c3327e7d27', { json: true })
            details.ip = response.data.ip
            details.postal = response.data.postal
            details.city = response.data.city
            details.region = response.data.region_code
            details.country = response.data.country_name
            details.latitude = response.data.latitude
            details.longitude = response.data.longitude
          } catch (error) {
            console.log(error)
          }
          const myConnectionDetailsRef = child(myConnectionRef, 'details')
          await set(myConnectionDetailsRef, details)

          // Manage the lastOnline timestamp
          const lastOnlineRef = child(myUserRef, 'lastOnline')
          // When I disconnect, update the last time I was seen online
          await onDisconnect(lastOnlineRef).set(serverTimestamp())
          await set(lastOnlineRef, false)
          context.commit('setMyLastOnlineRef', lastOnlineRef)
        } else {
          context.commit('setMyConnectionRef', undefined)
        }
      })

      // Listen for store mutations for you
      const usersRef = ref(db, 'users')
      const snapshots = await get(usersRef)
      snapshots.forEach(child => {
        if (child.key !== myId) {
          context.commit('setYourId', child.key)
        }
      })
      const yourUserRef = child(usersRef, context.state.yourId)
      onValue(yourUserRef, async snapshot => {
        context.commit('setYourLastOnline', snapshot.val().lastOnline)
        context.commit('setYourName', snapshot.val().name)
        context.commit('setYourPicUri', snapshot.val().picUri)
        context.commit('setYourTyping', snapshot.val().isTyping)
        await context.dispatch('getStorageUrl', snapshot.val().picUri)
      })
    },

    logMeOut: async(context) => {
      if (context.state.myConnectionRef) {
        const myConnectionEndedRef = child(context.state.myConnectionRef, 'ended')
        await set(myConnectionEndedRef, serverTimestamp())
      }
      if (context.state.myLastOnlineRef) {
        await set(context.state.myLastOnlineRef, serverTimestamp())
      }
      context.commit('resetStore')
      await signOut(auth)
    },

    getStorageUrl: async(context, uri) => {
      if (!uri) return undefined
      if (!context.state.urlCache[uri]) {
        const assetRef = storageRef(storage, uri)
        const url = await getDownloadURL(assetRef)
        context.commit('addUrlToCache', { uri, url })
      }
      return context.state.urlCache[uri]
    }
  }
})

export default store
