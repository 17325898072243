<template>
  <div>

    <nav class="navbar is-fixed-top" :class="connected ? '' : 'is-light'" role="navigation" aria-label="main-navigation">
      <div class="container" style="padding: 16px;">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <a class="button is-primary" @click="logOut">
                <span class="icon">
                  <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                </span>
              </a>
            </div>
          </div>

          <div class="level-item">
            <p class="is-size-7">
              {{ yourName }} : {{ yourStatus }}
            </p>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span v-if="yourTyping" class="icon has-text-info">
                <i class="fas fa-comment" aria-hidden="true"></i>
              </span>
            </div>

            <div class="level-item">
              <div class="dropdown is-right is-hoverable">
                <div class="dropdown-trigger">
                  <button class="button" :class="connected ? 'is-white' : 'is-ligght'" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span class="icon is-small">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <div class="dropdown-item" @click="loadMore">
                      <span class="icon">
                        <i class="fas fa-sync-alt" :class="{'fa-spin' : isLoadingMore }"></i>
                      </span>
                      Load more
                    </div>
                    <div class="dropdown-item" @click="goImages">
                      <span class="icon">
                        <i class="fas fa-th"></i>
                      </span>
                      Images
                    </div>
                    <div class="dropdown-item" @click="goHistory">
                      <span class="icon">
                        <i class="far fa-calendar-alt"></i>
                      </span>
                      History
                    </div>
                    <div class="dropdown-item" @click="goSearch">
                      <span class="icon">
                        <i class="fas fa-search"></i>
                      </span>
                      Search
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="container">
      <vue-pull-refresh :on-refresh="onRefresh" :config="config">
        <message v-for="item in messages"
          class="columns is-mobile"
          :key="item.key"
          :item="item"
          :reactionsOpened="hasOpenedReactions(item)"
          @click.native="openReactions(item)"
          @setReaction="setReaction"
          v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, item.key)">
        </message>
        <br>
      </vue-pull-refresh>
    </div>

    <nav class="navbar is-fixed-bottom">
      <div class="container" style="padding: 16px">
        <div class="level is-mobile">
          <div class="level-left" v-if="!message">
            <div class="level-item is-marginless">
              <div v-if="!isUploading" class="file is-medium is-white">
                <label class="file-label">
                  <input class="file-input" type="file" multiple name="resume" accept="image/*, video/*" @change="uploadFiles($event)">
                  <span class="file-cta is-paddingless">
                    <span class="file-icon" style="color:#1976D2">
                      <i class="fas fa-camera"></i>
                    </span>
                  </span>
                </label>
              </div>
              <div v-else>
                <radial-progress-bar
                                :diameter="24"
                                :stroke-width="1"
                                start-color="#9E9E9E"
                                stop-color="#9E9E9E"
                                inner-stroke-color="#FFFFFF"
                                :completed-steps="bytesTransferred"
                                :total-steps="totalBytes">
                </radial-progress-bar>
              </div>
            </div>

            <div class="level-item">
              <a class="button is-white" @click="goGifs">
                <span class="icon" style="color:#1976D2">
                  <i class="far fa-lg fa-image"></i>
                </span>
              </a>
            </div>
          </div>

          <div class="level-item is-marginless">
            <textarea class="textarea" rows="1" v-bind:value="message" v-on:input="updateInput($event.target.value)"></textarea>
          </div>

          <div class="level-right">
            <div class="level-item">
              <a class="button is-white" @click="addComment">
                <span class="icon is-medium" style="color:#1976D2">
                  <i class="fas fa-lg fa-paper-plane"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

  </div>
</template>

<script>
import moment from 'moment'
import RadialProgressBar from 'vue-radial-progress'
import VuePullRefresh from 'vue-pull-refresh'
import Message from './Message'
import { db, storage } from '../config'
import { ref, child, set, push, query, orderByChild, limitToLast, onChildAdded, onChildChanged, onChildMoved, onChildRemoved } from 'firebase/database'
import { ref as storageRef, uploadBytesResumable } from 'firebase/storage'
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)

const chatRef = ref(db, 'chat')

export default {
  name: 'chat',
  components: {
    RadialProgressBar,
    VuePullRefresh,
    Message
  },

  data() {
    return {
      listeners: {},
      messages: [],
      message: undefined,
      limit: 0,
      now: new Date(),
      isUploading: false,
      bytesTransferred: 0,
      totalBytes: 0,
      isLoadingMore: false,
      config: {
        errorLabel: '',
        startLabel: '',
        readyLabel: '',
        loadingLabel: ''
      },
      openReactionsKey: undefined,
      shouldScroll: true
    }
  },

  mounted() {
    setInterval(() => {
      this.now = new Date()
    }, 5000)
    this.loadMore()
  },

  updated() {
    this.$nextTick().then(() => {
      if (this.shouldScroll) {
        window.scrollTo(0, document.body.scrollHeight)
      }
    })
  },

  beforeDestroy() {
    this._unbindMessages()
  },

  computed: {
    connected() {
      return this.$store.state.myConnectionRef
    },

    yourName() {
      return this.$store.state.yourName
    },

    yourStatus() {
      if (!this.$store.state.yourLastOnline) {
        return 'Connecté'
      } else {
        return 'Actif ' + moment(this.$store.state.yourLastOnline).locale('fr').from(moment(this.now))
      }
    },

    yourTyping() {
      return this.$store.state.yourTyping
    }
  },

  methods: {
    visibilityChanged(visible, entry, key) {
      if (this.messages[this.messages.length - 1]?.key === key) {
        this.shouldScroll = visible
      }
    },

    logOut() {
      this.$store.dispatch('logMeOut').then(() => {
        this.$router.replace('/')
      })
    },

    openReactions(item) {
      if (this.openReactionsKey === item.key) {
        this.openReactionsKey = undefined
      } else {
        this.openReactionsKey = item.key
      }
    },

    hasOpenedReactions(item) {
      return item.key === this.openReactionsKey
    },

    setReaction(key, reaction) {
      const reactionRef = child(chatRef, `${key}/reaction`)
      set(reactionRef, reaction)
    },

    addComment() {
      this.message = this.message.trim()
      if (this.message !== '') {
        const item = {
          message: this.message,
          name: this.$store.state.myName,
          timestamp: moment().unix(),
          userId: this.$store.state.myId,
          userPicUri: this.$store.state.myPicUri
        }
        push(chatRef, item)
        this.updateInput('')
      }
    },

    updateInput(value) {
      this.message = value
      const isTypingRef = ref(db, `users/${this.$store.state.myId}/isTyping`)
      set(isTypingRef, !!this.message)
      this.shouldScroll = true
    },

    async uploadFiles($event) {
      const files = $event.target.files
      for (const file of files) {
        try {
          await this.uploadFile(file)
        } catch (error) {
          console.log(error)
        }
      }
    },

    async uploadFile(file) {
      return new Promise((resolve, reject) => {
        // Upload file to the object 'images/filename'
        const filename = 'tmp/' + new Date().getTime().toString()
        const metadata = {
          customMetadata: {
            userId: this.$store.state.myId
          }
        }
        const fileRef = storageRef(storage, filename)
        const uploadTask = uploadBytesResumable(fileRef, file, metadata)
        this.isUploading = true
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed', // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.bytesTransferred = snapshot.bytesTransferred
            this.totalBytes = snapshot.totalBytes
          }, error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            this.isUploading = false
            reject(error)
          }, () => {
            // Upload completed successfully, now we can get the download URL
            this.isUploading = false
            resolve()
          })
      })
    },

    loadMore() {
      this.isLoadingMore = true
      this.$forceUpdate()
      this.limit += 25
      this._bindMessages()
      this.isLoadingMore = false
    },

    onRefresh() {
      return new Promise((resolve, reject) => {
        this.loadMore()
        resolve()
      })
    },

    goImages() {
      this.$router.push('/images')
    },

    goHistory() {
      this.$router.push('/history')
    },

    goSearch() {
      this.$router.push('/search')
    },

    goGifs() {
      this.$router.push('/gifs')
    },

    _bindMessages() {
      this._unbindMessages()
      const chatQuery = query(chatRef, orderByChild('timestamp'), limitToLast(this.limit))
      const addOff = onChildAdded(chatQuery, (snapshot, previousChildKey) => {
        const previousIndex = this.messages.findIndex(message => message.key === previousChildKey)
        const message = snapshot.val()
        message.key = snapshot.key
        this.messages.splice(previousIndex + 1, 0, message)
      })
      const changeOff = onChildChanged(chatQuery, (snapshot) => {
        const index = this.messages.findIndex(message => message.key === snapshot.key)
        const message = snapshot.val()
        message.key = snapshot.key
        this.messages.splice(index, 1, message)
      })
      const moveOff = onChildMoved(chatQuery, (snapshot, previousChildKey) => {
        const index = this.messages.findIndex(message => message.key === snapshot.key)
        this.messages.splice(index, 1)
        const previousIndex = this.messages.findIndex(message => message.key === previousChildKey)
        const message = snapshot.val()
        message.key = snapshot.key
        this.messages.splice(previousIndex + 1, 0, message)
      })
      const removeOff = onChildRemoved(chatQuery, snapshot => {
        const index = this.messages.findIndex(message => message.key === snapshot.key)
        this.messages.splice(index, 1)
      })

      this.listeners = { addOff, changeOff, moveOff, removeOff }
    },

    _unbindMessages() {
      this.messages = []
      this.listeners.addOff?.()
      this.listeners.changeOff?.()
      this.listeners.moveOff?.()
      this.listeners.removeOff?.()
      this.listeners = {}
    }
  }
}
</script>

<style scoped>

.pull-down-header {
  background-color: none !important;
}

.gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.gallery-item {
  flex: 1 0 15em;
  margin: 1em;
}
.gallery-img {
  display: block;
  width: 100%;
}

</style>
