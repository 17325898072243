<template>
  <div>
    <nav
      class="navbar is-fixed-top"
      :class="connected ? '' : 'is-light'"
      role="navigation"
      aria-label="main-navigation"
    >
      <div
        class="container"
        style="padding: 16px;"
      >
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <a
                class="button is-primary"
                @click="visible=true"
              >
                <span class="icon is-small">
                  <i
                    class="far fa-calendar-alt"
                    aria-hidden="true"
                  />
                </span>
              </a>
            </div>
          </div>

          <div v-show="visible">
            <input ref="calendarTrigger">
          </div>

          <div class="level-item">
            <span class="tag is-small is-light">
              {{ formattedStart }}
            </span>
            <span class="tag is-small is-light">
              {{ formattedEnd }}
            </span>
            <span class="button is-small is-rounded is-info">
              {{ numberMessages }}
            </span>
          </div>

          <div class="level-right">
            <div class="level-item">
              <a
                class="button"
                :class="connected ? 'is-white' : 'is-light'"
                @click="goHome()"
              >
                <span class="icon is-small">
                  <i class="fas fa-home" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="container">
      <message
        v-for="item in messages"
        :key="item.key"
        class="columns is-mobile"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import { db } from '../config'
import { ref, query, orderByChild, startAt, endAt, get } from 'firebase/database'
import moment from 'moment'
import Message from './Message'
import bulmaCalendar from 'bulma-calendar'

export default {
  name: 'History',
  components: {
    Message
  },

  data() {
    return {
      startDate: this.$route.query.startDate ? new Date(parseInt(this.$route.query.startDate)) : new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: this.$route.query.endDate ? new Date(parseInt(this.$route.query.endDate)) : new Date(new Date().setHours(23, 59, 59, 999)),
      visible: false,
      messages: [],
      numberMessages: 0
    }
  },

  computed: {
    formattedStart() {
      return moment(this.startDate).locale('fr').format('Do MMM YYYY')
    },

    formattedEnd() {
      return moment(this.endDate).locale('fr').format('Do MMM YYYY')
    },

    connected() {
      return this.$store.state.myConnectionRef
    }
  },

  mounted() {
    const calendar = bulmaCalendar.attach(this.$refs.calendarTrigger, {
      type: 'date',
      startDate: this.startDate,
      endDate: this.endDate,
      isRange: true,
      weekStart: 1,
      lang: 'fr',
      displayMode: 'dialog',
      closeOnSelect: true
    })[0]
    calendar.on('select', e => {
      this.startDate = e.data.startDate
      this.endDate = new Date(e.data.endDate.setHours(23, 59, 59, 999))
    })
    calendar.on('hide', () => {
      this.visible = false
      this.updateMessages()
    })
    this.updateMessages()
  },

  methods: {
    async updateMessages() {
      this.messages.splice(0)

      const startTimestamp = this.startDate.getTime() / 1000
      const endTimestamp = this.endDate.getTime() / 1000
      const chatRef = ref(db, 'chat')
      const history = query(chatRef, orderByChild('timestamp'), startAt(startTimestamp), endAt(endTimestamp))
      const snapshot = await get(history)
      this.numberMessages = snapshot.size
      snapshot.forEach(child => {
        const message = child.val()
        message.key = child.key
        this.messages.push(message)
      })
    },

    goHome() {
      this.$router.push('/chat')
    }
  }
}
</script>
