<template>
  <div>
    <nav
      class="navbar is-fixed-top"
      :class="connected ? '' : 'is-light'"
      role="navigation"
      aria-label="main-navigation"
    >
      <div
        class="container"
        style="padding: 16px;"
      >
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div
                class="control is-expanded has-icons-right"
                :class="isSearching ? 'is-loading' : ''"
              >
                <input
                  ref="inputField"
                  v-model="gifQuery"
                  class="input"
                  type="text"
                  placeholder="Search for GIFs"
                  @keyup.enter="search"
                >
                <span
                  v-if="!isSearching"
                  class="icon is-small is-right is-link"
                  @click="search"
                >
                  <i class="fas fa-search" />
                </span>
              </div>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <a
                class="button"
                :class="connected ? 'is-white' : 'is-light'"
                @click="goHome"
              >
                <span class="icon is-small">
                  <i class="fas fa-home" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="container is-fluid">
      <gallery
        :images="gifs"
        thumb-key="previewUrl"
        full-key="downloadUrl"
        :selectable="true"
        @onSelected="onSelected"
      />
    </div>

    <nav class="navbar is-fixed-bottom">
      <div
        class="container"
        style="padding: 16px"
      >
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <a
                class="button is-primary"
                :disabled="gifs.length >= totalCount"
                :class="isLoading ? 'is-loading' : ''"
                @click="loadMore"
              >
                <span class="icon">
                  <i
                    class="fas fa-sync-alt"
                    aria-hidden="true"
                  />
                </span>
                <span>More</span>
              </a>
            </div>
          </div>

          <div class="level-item">
            <div class="select is-info">
              <select
                v-model="searchMode"
                @change="search"
              >
                <option>gifs</option>
                <option>stickers</option>
              </select>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <a
                class="button is-link"
                :disabled="selectedIndex === undefined || isUploading"
                :class="isUploading ? 'is-loading' : ''"
                @click="send"
              >
                <span class="icon">
                  <i class="fas fa-paper-plane" />
                </span>
                <span>Send</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import GphApiClient from 'giphy-js-sdk-core'
import Gallery from './Gallery'
import axios from 'axios'
import { storage } from '../config'
import { ref, uploadBytesResumable } from 'firebase/storage'

const giphyClient = GphApiClient('u4CaKnxY9ei6moAjOcRaTXL5C4aTHXcJ')

export default {
  name: 'Gifs',
  components: {
    Gallery
  },

  data() {
    return {
      gifQuery: undefined,
      isSearching: false,
      isLoading: false,
      searchMode: 'gifs',
      isGiphyModalOpened: false,
      gifs: [],
      totalCount: 0,
      lang: 'fr',
      selectedIndex: undefined,
      bytesTransferred: 0,
      totalBytes: 0,
      isUploading: false
    }
  },

  computed: {
    connected() {
      return this.$store.state.myConnectionRef
    }
  },

  methods: {
    goHome() {
      this.$router.push('/chat')
    },

    search() {
      this.isSearching = true
      this.$refs.inputField.blur()
      this.gifs = []
      this.loadMore().then(() => {
        this.isSearching = false
      })
    },

    loadMore() {
      this.isLoading = true
      let endpoint = 'search'
      if (!this.gifQuery) {
        endpoint = 'trending'
      }
      return giphyClient[endpoint](this.searchMode, { q: this.gifQuery, offset: this.gifs.length, limit: 20, lang: this.lang })
        .then(response => {
          this.totalCount = response.pagination.total_count
          this.gifs.push(...response.data.map(gifObject => {
            return {
              previewUrl: gifObject.images.preview_gif.url,
              downloadUrl: gifObject.images.original.url,
              id: gifObject.id
            }
          }))
          this.isLoading = false
        })
    },

    onSelected(selectedIndex) {
      this.selectedIndex = selectedIndex
    },

    send() {
      const selectedGif = this.gifs[this.selectedIndex]
      axios(selectedGif.downloadUrl, {
        responseType: 'blob'
      }).then(response => {
        const file = response.data
        const filename = 'tmp/' + selectedGif.id
        const metaData = {
          customMetadata: {
            userId: this.$store.state.myId
          }
        }
        const fileRef = ref(storage, filename)
        const uploadTask = uploadBytesResumable(fileRef, file, metaData)
        this.isUploading = true
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed', snapshot => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.bytesTransferred = snapshot.bytesTransferred
          this.totalBytes = snapshot.totalBytes
        }, error => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          console.log(error)
          this.isUploading = false
        }, () => {
          // Upload completed successfully
          this.isUploading = false
          this.$router.push('/chat')
        })
      })
    }
  }

}
</script>
